<template>
  <!-- 立即申请 -->
  <div class="flexBox">
    <!-- 广告位置 图片 -->
    <div>
      <!-- <el-image
                style="width: 530px; height: 530px"
                :src="detalis&&detalis.adv_list&&detalis.adv_list[0].img_url"
                fit="fill"
      ></el-image>-->
      <!-- <div class="block"> -->
        <div v-if="detalis&&detalis.value&&detalis.value.adv_list.length != 0" >
      <el-carousel  style="height:500px;width: 450px">
        <el-carousel-item
          style="height:500px;width: 450px"
          v-for="(item,index) in detalis&&detalis.value&&detalis.value.adv_list"
          :key="index"
        >
          <!-- <h3 class="small">{{ item.img_url }}</h3> -->
          <el-image
            style="width: 450px; height:500px"
            :src="item.img_url||'https://oss.testfw.cn/images/2022/10/09/aac6301bbfa831bdf83dcf7300f038b4.jpg'"
            fit="fill"
          ></el-image>
        </el-carousel-item>
      </el-carousel>
      </div>
      <div v-else>
        <el-image
          style="width: 450px; height: 500px"
          :src="'https://oss.testfw.cn/images/2022/10/09/aac6301bbfa831bdf83dcf7300f038b4.jpg'"
          fit="fill"
        ></el-image>
      </div>
      <!-- </div> -->
    </div>
    <!-- 供应商入围 -->
    <div class="invitation">
      <p class="redTitle">供应商入围邀请</p>
      <!-- 公司加图片logo -->
      <div class="logo">
        <!-- 图片 -->
        <div style="margin-right:5px">
          <el-image
            style="width: 20px; height: 20px"
            :src="detalisProps&&detalisProps.company&&detalisProps.company.company_logo||'https://oss.testfw.cn/images/2022/10/09/06e7149cdf270f817b34a6a174c6f2c2.png'"
            fit="fill"
          >
            <div slot="error" class="image-slot">
             <el-image
            style="width: 20px; height: 20px"
            src="https://oss.testfw.cn/images/2022/10/09/06e7149cdf270f817b34a6a174c6f2c2.png"
            fit="fill"
          ></el-image>
            </div>
          </el-image>
        </div>
        <div>
          <p
            class="company"
          >{{ detalisProps&&detalisProps.company&&detalisProps.company.name||'--'}}</p>
        </div>
      </div>
      <!-- 供货范围 -->
      <el-row class="fanwei">
        <el-col v-for="(item,index) in types_popup" :key="index" :span="8" align="left">
          <div class="utility">
            <div class="redCss"></div>
            <span class="slh">{{item.name||'--'}}</span>
          </div>
        </el-col>
      </el-row>
      <!-- 受邀请 -->
      <div class="beLnvited">
        <p>受邀供应商名称：{{detalisProps.invite_name||'--'}}</p>
        <p style="margin:5px 0">受邀联系人：{{detalisProps.invite_contact_name||'--'}}</p>
        <p>受邀联系人电话：{{detalisProps.invite_contact_mobile||'--'}}</p>
      </div>
      <!-- 介绍 -->
      <div style="margin-top:20px">
        <p>{{detalisProps&&detalisProps.company&&detalisProps.company.name||'--'}}邀请您入驻SRM平台并成为其入围供应商</p>
        <p>点击【立即申请】进入您的专属申请页面</p>
      </div>
      <!-- 大按钮 -->
      <div style="margin-top:20px">
        <el-button @click="step" style="width:100%" type="danger">立即申请 </el-button>
      </div>
      <!-- 同意 -->
      <div class="ok">
        
        <div>
          <el-checkbox v-model="checked">同意</el-checkbox>
          <div
            class="ellipsistext xy"
            v-for="(item,index) in detalis&&detalis.agreement_list"
            :key="index"
          >《<span style="color:red" @click="goXY(item)" >{{item.title}}</span>》
          </div>供应商入围申请须知
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["detalis", "detalisProps"],
  data() {
    return {
      checked: false,
      types_popup: null,
      src:''
    };
  },
  created() {
    console.log(this.detalis)
    console.log(this.detalisProps)
    let params = {
      company_id: this.detalisProps.company_id
    };
    this.$axios.get("/crm/shortlist/classify/list", params).then(res => {
      if (res.code == 200) {
        console.log(res)
        this.types_popup = res.data;
        console.log(this.types_popup);
      }
    });
  },
  methods: {
    goXY(item){
      console.log(item)
      // :href="item.attachment.image_url"  target="_blank"
      // 下面一个注视的 params 传参 好像值没有带过去
      if(item.content){
        let routeUrl = this.$router.resolve({
        path: "/information/xieYi",
        query: {
          id: item.id,
          company_id:this.$route.query.k
        }
      })
      window.open(routeUrl.href, '_blank') 

      }else{
        window.open(item.attachment.image_url)
      }
    },
    // goXY(item){
    //   console.log(item)
    //   // :href="item.attachment.image_url"  target="_blank"
    //   if(item.content){
    //     let routeUrl = this.$router.resolve({
    //     name: "xieYi",
    //     params: {
    //       data: item.content
    //     }
    //   })
    //   window.open(routeUrl.href, '_blank') 

    //   }else{
    //     window.open(item.attachment.image_url)
    //   }
    // },





    step() {
      if (!this.checked) {
        this.$message.error("请先勾选同意后再入围");
        return;
      }
      let invite_code,invite_name
      invite_code = this.detalisProps.invite_code||''
      invite_name = this.detalisProps.invite_name||''
       if(process.env.NODE_ENV === 'development'){
         if(this.detalisProps.invite_method == 1){
            this.src = `http://localhost:17000/large/supplierCrm/quaInfo?k=${this.$route.query.k}&company_id=${this.detalisProps.company_id}&invite_code=${invite_code}&invite_name=${invite_name}`;
         }else{
            this.src = `http://localhost:17000/large/supplierCrm/quaInfo?k=${
       this.$route.query.k
      }&company_id=${this.detalisProps.company_id}`;
         }
          

      console.log(this.src)

        // ------
      //   this.src = `http://localhost:8080/#/supplierCrm/quaInfo?k=${
      //   this.k
      // }&company_id=${this.company_id}`;
      }else{
        if(this.detalisProps.invite_method == 1){
           this.src = `https://${document.domain}/_crm/large/supplierCrm/quaInfo?k=${this.$route.query.k}&company_id=${this.detalisProps.company_id}&invite_code=${invite_code}&invite_name=${invite_name}`;
        }else{
           this.src = `https://${document.domain}/_crm/large/supplierCrm/quaInfo?k=${
        this.$route.query.k
      }&company_id=${this.detalisProps.company_id}`;
        }
       
      }
      location.href = this.src
      this.$router.push({
        path: "/information",
        query: {
          k: this.$route.query.k,
          company_id: this.detalisProps.company_id
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.flexBox {
  width: 95%;
  padding: 40px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  .invitation {
    margin-left: 50px;
    width: 450px;
    height: 500px;
    background-color: rgba(255, 255, 255, 100);
    border: 1px solid rgba(229, 229, 234, 100);
    padding: 20px 40px;
    overflow: hidden;
    box-sizing: border-box;
    overflow: scroll;
   
    // 标题
    .redTitle {
      color: rgba(247, 72, 72, 100);
      font-size: 20px;
      text-align: center;
      font-family: SourceHanSansSC-regular;
      margin-bottom: 10px;
    }
    // 公司简称
    .company {
      color: rgba(51, 51, 51, 100);
      font-size: 14px;
    }
    // 有logo的
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
    }
    // 范围
    .utility {
      text-align: left;
      display: flex;
      // justify-content: center;
      align-items: center;
      margin: 15px 0;
    }
    // 受邀
    .beLnvited {
      width: 420px;
      height: 100px;
      background-color: rgba(252, 252, 252, 100);
      text-align: left;
      padding: 15px;
      box-sizing: border-box;
    }
    // 同意
    .ok {
      margin-top: 10px;
      text-align: left;
    }
  }
}
.redCss {
  margin-right: 5px;
  min-width: 8px;
  height: 8px;
  border-radius: 100%;
  background: red;

  // box-shadow: 0 2px 4px 0 rgba(0,0,0,.2)
}
.slh {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fanwei {
  height: 100px;
  overflow: hidden;
}
.ellipsistext {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.xy{
  display:inline-block;
  position: relative;
  top: 5px;
}
 ::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
</style>