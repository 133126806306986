<template>
    <div class="flexBox">
        <div class="invitation">
            <!-- 进度条 -->
            <p>头部进度条 马上一起改</p>
            <br>
            <!-- <step /> -->
            <!-- 标题字体 -->
            <div class="reject">
                <span class="el-icon-error"></span>
                <span>请补充入围申请资料</span>
            </div>
            <!-- 客户内容 -->
            <div class="info">
                <p>客户名称：{{'上海弋博钢材制造股份有限公司'}}</p>
                <p>联系人：{{'🥹'}}</p>
                <p>手机：{{'13333333'}}</p>
                <p>审批时间：{{"2021-08-05 18:23"}}</p>
                <p>审批意见：{{'请补充入围资质资料'}}</p>
            </div>
            <!-- 按钮 -->
            <div style="margin-top:100px">
                <el-button style="width:100%" type="danger">重新提交</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import step from "./step.vue";
export default {
  data() {
    return {};
  },
  components: {
    step
  }
};
</script>

<style lang="scss" scoped>
.flexBox {
  padding: 100px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  .invitation {
    margin-left: 66px;
    width: 520px;
    height: 528px;
    background-color: rgba(255, 255, 255, 100);
    border: 1px solid rgba(229, 229, 234, 100);
    padding: 40px 50px;
    overflow: hidden;
    box-sizing: border-box;
    .reject {
      text-align: left;
      span {
        color: rgba(255, 153, 89, 100);
        font-size: 28px;
        text-align: left;
        font-family: SourceHanSansSC-regular;
      }
    }
    .info {
      margin-top: 35px;
      height: 170px;
      background-color: rgba(252, 252, 252, 100);
      text-align: left;
      padding: 10px 20px;
      box-sizing: border-box;
      p{
          margin-bottom: 5px;
      }
    }
  }
}
</style>