<template>
<!-- 进度条 -->
    <div style="width:70%;margin:auto">
<el-steps :active="active" finish-status="success">
  <el-step title="基础信息"></el-step>
  <el-step title="入围资质"></el-step>
  <el-step title="核心企业审核"></el-step>
  <el-step title="入围审核反馈"></el-step>
</el-steps>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                  active: 2
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>