<template>
  <!-- 入围提交 -->
  <div>
    <el-container>
      <el-header style="height:85px">
        <topHead v-if="flag" :detalis="detalis" :detalisProps="detalisProps"/>
      </el-header>
      <el-main>
        <div class="card">
          <!-- 供应商入围邀请 -->
          <apply v-if="flag" :detalisProps="detalisProps" :detalis="detalis"/>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import topHead from "./components/topHead.vue";
import apply from "./components/apply.vue";
import success from "./components/success.vue";
import reject from "./components/reject.vue";
import fail from "./components/fail.vue";
import adopt from "./components/adopt.vue";
export default {
  data() {
    return {
      detalis: null,
      flag: false,
      detalisProps: null
    };
  },
  components: {
    topHead,
    apply,
    success,
    reject,
    fail,
    adopt
  },
  created() {
    this.query();
    this.getData()
  },
  methods: {
    // 请求激活接口
    query() {
      let params = {
        serial_number: this.$route.query.k
      };
      // 激活
      this.$axios.postJSON("/base/invite/active", params).then(res => {
        if (res.code == 200) {
          // this.detalisProps = res.data;
          // this.flag = true;
          // this.getMgt(res.data.company_id);
          // this.$message.success('已激活')
        } 
      });
    },
    // 获取信息
    getData(){
      let params = {
        serial_number: this.$route.query.k
      };
      this.$axios.postJSON("/base/invite/detail", params).then(res => {
            if (res.code == 200) {
              this.detalisProps = res.data;
              this.flag = true;
              this.getMgt(res.data.company_id);
            }
          });
    },
    // 获取设置
    getMgt(id) {
      this.$axios.get("/setting/shortlisted", { company_id: id }).then(res => {
        if (res.code == 200) {
          this.detalis = res.data;
          console.log(this.detalis);
          this.flag = true;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-container {
  width: 100%;

  .el-header {
    // background-color: #b3c0d1;
    color: #333;
    text-align: center;
    line-height: 85px;
    margin: auto;
    background-color: rgba(255, 255, 255, 100);
    box-shadow: 0px 2px 6px 4px rgba(0, 0, 0, 0.1);
  }
  .el-main {
    margin: auto;

    text-align: center;
    .card {
      width: 80%;

       margin: auto;
       margin-top: 30px;
      margin-bottom: 30px;
      background-color: #fff;
      min-height: 500px;
    }
  }
}
</style>