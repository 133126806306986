<template>
    <div class="flexBox">
        <div class="invitation">
            <!-- 进度条 -->
           <div class="stepBox">
        <!-- <el-steps :active="4" finish-status="success">
          <el-step title="基础信息"></el-step>
          <el-step title="入围资质"></el-step>
          <el-step title="核心企业审核"></el-step>
          <el-step title="入围审批"></el-step>
        </el-steps> -->
      </div>
            <br>
            <!-- <step /> -->
            <!-- 提交成功绿色字体 -->
            <div class="success">
                <span class="el-icon-success"></span>
                <span>提交成功，入围审核中</span>
            </div>
            <!-- 提交时间 -->
            <p class="time">
                <span>提交时间：</span>
                {{routerData.created_at||'--'}}
            </p>
            <!-- 入围单号 -->
            <p class="number">
                <span>入围申请单号：</span>
                {{routerData.serial_number||'--'}}
            </p>
            <!-- 链接 -->
            <el-row style="margin-top:15px">
                <el-col :span="20" style="text-align:left">
                    <span>
                        登录平台{{linkLog}},进入工作台查看审批动态
                        审批结果将以短信通知方式通知入围联系人
                    </span>
                </el-col>
                <el-col :span="4">
                    <el-link class="tag-read" @click="copyContentBtn" :data-clipboard-text="linkLog" :underline="false" type="primary">复制链接</el-link>
                </el-col>
            </el-row>
            <!-- 二维码 -->
            <el-row style="text-align:left;margin-top:15px">
                <el-col :span="4">二维码：</el-col>
                <el-col :span="8">
                    <div>
                        <el-image
                            style="width: 140px; height: 140px"
                            :src="invite_link_img"
                            fit="fill"
                        ></el-image>
                        <el-link @click="invitSetBtn" :underline="false" type="primary" style="text-align:center">复制二维码</el-link>
                    </div>
                </el-col>
            </el-row>
            <!-- 按钮 -->
            <div style="margin-top:20px">
                <el-button style="width:100%" type="danger">捕捉商机</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import step from "./step.vue";
import Clipboard from "clipboard";

export default {
  data() {
    return {
        routerData:null,
        invite_link_img:'',
        linkLog:''
    };
  },
  components: {
    step
  },
  created() {
    this.linkLog = location.origin+'/login'
      if(this.$route.query.k){
          this.routerData = this.$route.query
          console.log(this.routerData )
      }
      this.getShareCode();
  },
  methods: {
      getShareCode() {
      let data = {
        content: this.linkLog,
        img_size: 100
      };
      this.$utils
        .getQrcode(data)
        .then(res => {
          this.invite_link_img = res.img;
        })
        .catch(err => {
          console.log(err);
        });
    },
       // 复制链接
    copyContentBtn() {
      
      var clipboard = new Clipboard(".tag-read");
      let _this = this;
      clipboard.on("success", e => {
        this.$message.success("复制成功");
      });
      clipboard.on("error", e => {
        this.$message.error("该浏览器不支持复制");
      });
      this.clipboard = clipboard;
      setTimeout(() => {
        this.clipboard.destroy && this.clipboard.destroy();
      }, 500);
    },
    // 复制二维码
    invitSetBtn() {
      function b64toBlob(b64Data, contentType = null, sliceSize = null) {
        contentType = contentType || "image/png";
        sliceSize = sliceSize || 512;
        let byteCharacters = window.atob(
          b64Data.substring(b64Data.indexOf(",") + 1)
        );
        let byteArrays = [];
        for (
          let offset = 0;
          offset < byteCharacters.length;
          offset += sliceSize
        ) {
          let slice = byteCharacters.slice(offset, offset + sliceSize);
          let byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, { type: contentType });
      }

      let _this = this;
      function clip(base64Data) {
        try {
          if (document.body.createControlRange) {
            //IE 11 需要有个img标签
            var controlRange;
            var imgs = document.getElementById("invitedImg");
            imgs.onload = function() {
              controlRange = document.body.createControlRange();
              imgs.contentEditable = "true";
              controlRange.addElement(imgs);
              try {
                var successful = controlRange.execCommand("copy");
                var msg = successful ? "successful" : "unsuccessful";
                console.log("Copying text command was " + msg);
                _this.$message.success("复制成功");
              } catch (err) {
                console.log(err);
              }
            };
            imgs.src = "data:image/png;base64," + base64Data;
          } else {
            //chrome
            const blobInput = b64toBlob(base64Data, "image/png");
            const clipboardItemInput = new ClipboardItem({
              "image/png": blobInput
            });
            navigator.clipboard.write([clipboardItemInput]);
            _this.$message.success("复制成功");
          }
        } catch (e) {
          console.log(e);
          _this.$message.success("复制失败：" + JSON.stringify(e));
        }
      }
      clip(this.invite_link_img);
    }
  },
};
</script>

<style lang="scss" scoped>
.flexBox {
  padding: 100px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  .invitation {
    margin-left: 66px;
    width: 520px;
    height: 528px;
    background-color: rgba(255, 255, 255, 100);
    border: 1px solid rgba(229, 229, 234, 100);
    padding: 40px 50px;
    overflow: hidden;
    box-sizing: border-box;
    .stepBox {
      // width:0%;
      margin: auto;
    }
    .success {
      span {
        color: rgba(122, 199, 86, 100);
        font-size: 28px;
        text-align: left;
        font-family: SourceHanSansSC-regular;
      }
    }
    .time {
      margin-top: 20px;
      color: rgba(153, 153, 153, 100);
      font-size: 14px;
      text-align: left;
      font-family: SourceHanSansSC-regular;
    }
    .number {
      margin-top: 15px;
      text-align: left;
      color: rgba(51, 51, 51, 100);
      font-size: 14px;

      font-family: SourceHanSansSC-regular;
    }
  }
}
::v-deep .el-step__head.is-success {
  color: red;
  border-color: red;
}
::v-deep .el-step__title.is-success {
  color: red;
}
::v-deep .el-step__line-inner {
  background-color: red;
}
</style>