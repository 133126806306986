<template>
  <div class="flexBox">
    <div class="invitation">
      <!-- 进度条 -->
  
      <br>
      <!-- <step /> -->
      <!-- 标题字体 -->
      <div class="reject">
        <span class="el-icon-success"></span>
        <span>恭喜您通过审核，入围成功</span>
      </div>
      <!-- 客户内容 -->
      <div class="info">
        <p>客户名称：{{detalisProps&&detalisProps.company_name||'--'}}</p>
        <p>联系人：{{detalisProps&&detalisProps.contact&&detalisProps.contact.name||'--'}}</p>
        <p>手机：{{detalisProps&&detalisProps.contact&&detalisProps.contact.mobile||'--'}}</p>
        <p>入围日期：{{routerData.created_at||"--"}}</p>
        <p>入围类型：{{routerData.types_name||'--'}}</p>
        <p>入围类别：{{routerData.classify_name||'--'}}</p>
      </div>
      <br>
      <!-- 等加入审批再继续 -->
      <!-- <p>查看状态地址：
        <el-link type="primary" :underline="false" :href='codeLink'>{{codeLink}}</el-link>
      </p> -->
      <!-- 按钮 -->
      <div style="margin-top:100px">
        <el-button style="width:100%" type="danger" @click="goToHomePage">捕捉商机</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import step from "./step.vue";
export default {
  data() {
    return {
      routerData: null,
      detalisProps: null,
      aLink: null,
      codeLink: null
    };
  },
  components: {
    step
  },
  created() {
    if (this.$route.query.k) {
      console.log(this.$route.query)
      this.routerData = this.$route.query;
      this.codeLink = `http://localhost:8090/information/look?code=${
        this.$route.query.code
      }&company_id=${this.$route.query.company_id}`;
      // http://localhost:8080/#/
      // https://www.testfw.cn/platform/#/information/look
      console.log(this.routerData);
      let params = {
        // company_id: this.$route.query.company_id,
        // id: this.$route.query.id
        code:this.$route.query.code
      };
      this.$axios.get("/crm/shortlist/shortlist_detail", params).then(res => {
        if (res.code == 200) {
          this.detalisProps = res.data;
        }
      });
      // 跳转
      this.upLink(this.$route.query.company_id);
    }
  },
  methods: {
    upLink(id) {
      this.$axios.get("/base/company/detail", { company_id: id }).then(res => {
        if (res.code == 200) {
          this.aLink = res.data.webdomain;
        }
      });
    },
    goToHomePage() {
      const hone = `//${this.aLink}`;
      window.open(hone, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
.flexBox {
  padding: 100px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  .invitation {
    margin-left: 66px;
    width: 520px;
    height: 528px;
    background-color: rgba(255, 255, 255, 100);
    border: 1px solid rgba(229, 229, 234, 100);
    padding: 40px 50px;
    overflow: hidden;
    box-sizing: border-box;
    .stepBox {
      // width:0%;
      margin: auto;
    }
    .reject {
      text-align: left;
      span {
        color: rgba(122, 199, 86, 100);
        font-size: 28px;
        text-align: left;
        font-family: SourceHanSansSC-regular;
      }
    }
    .info {
      margin-top: 20px;
      height: 170px;
      background-color: rgba(252, 252, 252, 100);
      text-align: left;
      padding: 10px 20px;
      box-sizing: border-box;
      p {
        margin-bottom: 5px;
      }
    }
  }
}
::v-deep .el-step__head.is-success {
  color: red;
  border-color: red;
}
::v-deep .el-step__title.is-success {
  color: red;
}
::v-deep .el-step__line-inner {
  background-color: red;
}
</style>